<template>
  <div class="mt-3">
    <v-row class="mt-5 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon :to="`/wettkampf/${veranstaltung.id}/disziplinen`" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-file</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          {{ template.var.verband == 'DEU' ? 'GRUPPEN' : 'DISZIPLINEN' }}
          IMPORTIEREN
        </h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :color="template.colors.blocks"
          class="rounded-xl pa-0"
          :style="`color: ${template.colors.block_text};`"
        >
          <v-row justify="center">
            <v-col cols="11" class="pb-0 pt-5" v-if="!csvfile.length > 0">
              <h3>Info:</h3>
              <p>
                <b>Baue deine CSV-Datei wie folgt auf:</b>
                <br />
                Bitte verwende nur CSV-Dateien im Format UTF-8
              </p>
              <code>
                Name;Sportstätte;Importname;Beginndatum;Beginnuhrzeit;Endeuhrzeit;Linkregistrierung
                <br />
                Name1;Sportstätte1;Importname1;JJJJ-MM-TT;hh:mm;hh:mm;JA|NEIN
                <br />
                Name2;Sportstätte2;Importname2;JJJJ-MM-TT;hh:mm;hh:mm;JA|NEIN
              </code>

              <v-divider class="my-2"></v-divider>
            </v-col>
            <v-col cols="11" class="pb-0 pt-5">
              <vue-csv-import
                v-model="csvfile"
                :map-fields="[
                  'Name',
                  'Sportstätte',
                  'Importname',
                  'Beginndatum',
                  'Beginnuhrzeit',
                  'Endeuhrzeit',
                  'Linkregistrierung',
                ]"
                tableClass="table border"
                :headers="true"
                :autoMatchFields="true"
                v-if="!csvfile.length > 0"
              >
                <template slot="error">
                  Dateityp ist ungültig
                </template>

                <template slot="thead">
                  <p>
                    Weise die Spalten aus deiner Datei den benötigten Feldern zu
                  </p>
                  <tr>
                    <th style="border-bottom: 1px solid #000;">
                      Benötigte Felder
                    </th>
                    <th style="border-bottom: 1px solid #000;">
                      Spalten in deiner Datei
                    </th>
                  </tr>
                </template>

                <template slot="next" slot-scope="{ load }">
                  <v-btn
                    large
                    block
                    outlined
                    class="my-3"
                    :color="template.colors.primary"
                    @click.prevent="load"
                  >
                    DATEI LADEN
                  </v-btn>
                </template>

                <template slot="submit" slot-scope="{ submit }">
                  <v-btn
                    large
                    :color="template.colors.primary"
                    @click.prevent="submit"
                  >
                    IMPORT STARTEN
                  </v-btn>
                </template>
              </vue-csv-import>
            </v-col>
            <v-col cols="11" class="pb-0 pt-5" v-if="csvfile.length > 0">
              <v-row>
                <v-col cols="12">
                  <h2>Import-Vorschau</h2>
                </v-col>
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            Sportstätte
                          </th>
                          <th class="text-left">
                            Importname
                          </th>
                          <th class="text-left">
                            Beginndatum
                          </th>
                          <th class="text-left">
                            Beginnuhrzeit
                          </th>
                          <th class="text-left">
                            Endeuhrzeit
                          </th>
                          <th class="text-left">
                            Linkregistrierung
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in csvfile" :key="index">
                          <td>{{ row.Name }}</td>
                          <td>{{ row.Sportstätte }}</td>
                          <td>{{ row.Importname }}</td>
                          <td>
                            {{ parseDate(row.Beginndatum) }}
                          </td>
                          <td>{{ row.Beginnuhrzeit }}</td>
                          <td>{{ row.Endeuhrzeit }}</td>
                          <td>
                            <v-icon
                              :color="
                                row.Linkregistrierung == 'JA' ? 'green' : 'red'
                              "
                            >
                              {{
                                row.Linkregistrierung == 'JA'
                                  ? 'mdi-check-bold'
                                  : 'mdi-close'
                              }}
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-center pb-0">
              <v-card
                class="rounded-b-xl pa-4"
                :color="template.colors.primary"
                :disabled="loading || csvfile.length == 0"
                @click="startImport()"
              >
                <span class="font-weight-bold" v-if="!loading">
                  <v-icon class="mr-2">mdi-upload</v-icon>
                  ANMELDUNGEN IMPORTIEREN
                </span>
                <v-progress-circular
                  indeterminate
                  :color="template.colors.inline_primary_text"
                  :style="`color: ${template.colors.primary};`"
                  v-if="loading"
                ></v-progress-circular>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from 'firebase'
import store from '../../../../../store'
import router from '../../../../../routes/index'
import { VueCsvImport } from 'vue-csv-import'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      csvfile: [],
      loading: false,
      disziplinen: [],
    }
  },
  components: { VueCsvImport },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.data.uid)
      .collection('Veranstaltung')
      .doc(this.veranstaltung.id)
      .collection('Disziplin')
      .onSnapshot((snap) => {
        this.disziplinen = []
        var i = 0
        snap.forEach((doc) => {
          this.disziplinen.push(doc.data())
          this.disziplinen[i].id = doc.id
          i++
        })
      })
  },
  methods: {
    parseDate(datum) {
      datum = datum.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    parse_disziplin(data) {
      var daten = this.get_disziplinen(data)
      var text = ''
      for (let i = 0; i < daten.objects.length; i++) {
        if (i != 0) {
          text += '\n'
        }
        text += daten.objects[i].name
        if (daten.objects[i].altersklasse) {
          text += ' (' + daten.objects[i].altersklasse + ')'
        }
      }
      return text
    },
    get_disziplinen(data) {
      var output = {
        objects: [],
        ids: [],
      }
      data = data.split(',')
      for (let i = 0; i < this.disziplinen.length; i++) {
        for (let j = 0; j < data.length; j++) {
          if (this.disziplinen[i].importname == data[j]) {
            output.objects.push(this.disziplinen[i])
            output.ids.push(this.disziplinen[i].id)
          }
        }
      }
      return output
    },
    async startImport() {
      if (this.csvfile) {
        this.loading = true
        var i
        for (i = 0; i < this.csvfile.length; i++) {
          await firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Veranstaltung')
            .doc(this.veranstaltung.id)
            .collection('Disziplin')
            .add({
              name: this.csvfile[i].Name,
              sportstaette: this.csvfile[i].Sportstätte,
              importname: this.csvfile[i].Importname,
              public: this.csvfile[i].Linkregistrierung == 'JA' ? true : false,
              beginn: {
                datum: this.csvfile[i].Beginndatum,
                uhrzeit: this.csvfile[i].Beginnuhrzeit,
              },
              ende: {
                datum: this.csvfile[i].Beginndatum,
                uhrzeit: this.csvfile[i].Endeuhrzeit,
              },
            })
        }
        this.loading = false
        router.push('/wettkampf/' + this.veranstaltung.id + '/disziplinen')
      }
    },
  },
}
</script>

<style scoped>
.border {
  border-bottom: 1px solid #000;
}
</style>
